
import { defineComponent } from "vue";

import {
  projectsRecordsDetailCN,
  projectsRecordsDetailEN
} from "./ProjectsData";

export default defineComponent({
  setup() {
    let detailArray: Array<string> = [];

    return {
      projectsRecordsDetailCN,
      projectsRecordsDetailEN,
      detailArray
    };
  }
  // methods: {
  //   getRecordDetail(key: string, isCN: boolean) {
  //     if (isCN) {
  //       this.detailArray = projectsRecordsDetailCN[key];
  //     } else {
  //       this.detailArray = projectsRecordsDetailEN[key];
  //     }
  //     return this.detailArray;
  //   }
  // }
});
